import React from 'react';

export default function Footer() {
  return (
    <div>
      <span id="siteseal" className="fixed-bottom">
        <script
          async
          type="text/javascript"
          src="https://seal.godaddy.com/getSeal?sealID=Ox2lmEJ41tiXrXyr2wd1qFYWes7Wu2Q81pLrKKHToHVwAuiJzx0wUbb6SC3o"
        ></script>
      </span>
    </div>
  );
}
