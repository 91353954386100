import React, { useState, useEffect } from 'react';
import DocTypes from './DocTypes';
import axios from 'axios';
import AddUser from './modals/AddUser';
//import Switch from 'react-switch';

export default function Admin(props) {
  const [state, setState] = useState({
    users: [],
    roles: ['administrator', 'operator', 'view-only'],
  });

  let { users, roles } = state;

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUsers() {
    axios
      .get(`${props.url}:4000/api/v1/getusers`, {
        headers: { token: localStorage.getItem('token') },
      })
      .then((res) => {
        if (res.data.success) {
          setState({ ...state, users: res.data.users });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function setPassword(userid) {
    let newpassword = prompt('Enter new password');

    if (newpassword === '') {
      alert('Please enter a valid password');
    } else if (newpassword) {
      axios
        .post(
          `${props.url}:4000/api/v1/setpassword`,
          {
            id: userid,
            newpassword,
          },
          { headers: { token: localStorage.getItem('token') } },
        )
        .then((res) => {
          alert(res.data.message);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleSelect(e, userid) {
    axios
      .post(
        `${props.url}:4000/api/v1/setrole`,
        {
          id: userid,
          newrole: e.target.value,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        alert(res.data.message);
      })
      .catch((error) => {
        alert(error);
      });
  }

  function deleteUser(userid) {
    axios
      .post(
        `${props.url}:4000/api/v1/deleteuser`,
        {
          id: userid,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          getUsers();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleClick(e, id) {
    let localusers = users;
    let notifications = null;

    for (var user of localusers) {
      if (user.id === id) {
        if (e.target.checked) {
          user.notifications = 1;
          notifications = 1;
        } else {
          user.notifications = 0;
          notifications = 0;
        }
      }
    }
    setState({ ...state, users: localusers });

    axios
      .post(
        `${props.url}:4000/api/v1/setnotifications`,
        {
          id,
          notifications: notifications,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          // getUsers();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleEnabledClick(e, id) {
    let localusers = users;
    let enabled = null;

    for (var user of localusers) {
      if (user.id === id) {
        if (e.target.checked) {
          user.enabled = 1;
          enabled = 1;
        } else {
          user.enabled = 0;
          enabled = 0;
        }
      }
    }
    setState({ ...state, users: localusers });

    axios
      .post(
        `${props.url}:4000/api/v1/setenabled`,
        {
          id,
          enabled,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          // getUsers();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className="row">
      <div className="col-auto">
        <AddUser url={props.url} getUsers={getUsers} />
        {/*         <button onClick={() => addUser()}>Add User</button>
         */}
        <br />
        <br />
        <table className="table table-sm table-bordered table-striped table-centered table-responsive">
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Login</th>
              <th>Password</th>
              <th>Last Set</th>
              <th>Role</th>
              <th>Email</th>
              <th>Notifications</th>
              <th>Enabled</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.username}</td>
                <td>{user.lastlogin}</td>
                <td>
                  <button
                    type="button"
                    className="fa-button"
                    onClick={() => setPassword(user.id)}
                  >
                    <i className="fa fa-key" aria-hidden="true" />
                  </button>
                </td>
                <td>{user.passwordlastset}</td>
                <td>
                  <select id="role" onChange={(e) => handleSelect(e, user.id)}>
                    <option defaultValue>{user.role}</option>
                    {roles
                      .filter((role) => role !== user.role)
                      .map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                  </select>
                </td>
                <td>{user.email}</td>
                <td>
                  {/* <input
                    type="checkbox"
                    checked={user.notifications}
                    onChange={}
                  /> */}
                  <input
                    onChange={(e) => handleClick(e, user.id)}
                    checked={user.notifications}
                    type="checkbox"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => handleEnabledClick(e, user.id)}
                    checked={user.enabled}
                    type="checkbox"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="fa-button"
                    onClick={() => deleteUser(user.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DocTypes url={props.url} />
      <div className="col-auto">
        <ul>
          <li>
            You can add users and doctypes and change passwords and roles on
            this page.
          </li>
          <li>
            Checking the <i>Notifications</i> Checkbox will email that user if
            any of their documents are about to expire; once at 30 days left and
            every day after 15 days left.
          </li>
        </ul>
      </div>
    </div>
  );
}
