import { jwtDecode } from 'jwt-decode';

export function isAuthenticated() {
  let token = localStorage.getItem('token');

  if (token === null || !token || isTokenExpired(token)) {
    return false;
  } else {
    return true;
  }
}

export function isTokenExpired(token) {
  let date = getTokenExpirationDate(token);
  if (date === undefined) return false;
  return !(date.valueOf() > new Date().valueOf());
}

export function getTokenExpirationDate(token) {
  let decoded = jwtDecode(token);
  if (decoded.exp === undefined) {
    return null;
  } else {
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }
}

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('apollo-cache-persist');
  localStorage.removeItem('uuid');

  window.location.href = '/login';
}

export function isRole(role) {
  let token = localStorage.getItem('token');
  if (token == null || !token || isTokenExpired(token)) {
    return false;
  } else {
    if (jwtDecode(token).role === role) {
      return true;
    } else {
      return false;
    }
  }
}
export function getUser() {
  let token = localStorage.getItem('token');
  if (token) {
    let decoded = jwtDecode(token);
    if (decoded.exp === undefined) {
      return null;
    } else {
      return decoded.username;
    }
  }
}
export function getId() {
  let token = localStorage.getItem('token');
  if (token) {
    let decoded = jwtDecode(token);
    if (decoded.exp === undefined) {
      return null;
    } else {
      return decoded.id;
    }
  }
}
