import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function DocTypes(props) {
  const [state, setState] = useState({
    docTypes: [],
  });

  let { docTypes } = state;

  useEffect(() => {
    getDocTypes();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDocTypes() {
    axios
      .get(`${props.url}:4000/api/v1/getdoctypes`, {
        headers: { token: localStorage.getItem('token') },
      })
      .then((res) => {
        if (res.data.success) {
          setState({ ...state, docTypes: res.data.doctypes });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function addDocType() {
    let doctype = prompt('Enter new doctype name');

    if (doctype === '') {
      alert('Please enter a valid doctype name');
    } else if (doctype) {
      axios
        .post(
          `${props.url}:4000/api/v1/adddoctype`,
          {
            doctype,
          },
          { headers: { token: localStorage.getItem('token') } },
        )
        .then((res) => {
          if (res.data.success) {
            getDocTypes();
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function deleteDocType(doctypeid) {
    axios
      .post(
        `${props.url}:4000/api/v1/deletedoctype`,
        {
          id: doctypeid,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          getDocTypes();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className="row">
      <div className="col-auto">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addDocType()}
        >
          Add Doc Type
        </button>

        <br />
        <br />
        <table className="table table-sm table-bordered table-striped table-centered table-responsive">
          <thead>
            <tr>
              <th>Doc Type</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {docTypes.map((doctype, index) => (
              <tr key={index}>
                <td>{doctype.doctypename}</td>
                <td>
                  <button
                    type="button"
                    className="fa-button"
                    onClick={() => deleteDocType(doctype.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
