import React from 'react';
import axios from 'axios';
import { getId } from '../Auth';

let id = getId();

export default function About(props) {
  function setPassword() {
    let newpassword = prompt('Enter new password');

    if (newpassword === '') {
      alert('Please enter a valid password');
    } else if (newpassword) {
      axios
        .post(
          `${props.url}:4000/api/v1/setpassword`,
          {
            id,
            newpassword,
          },
          { headers: { token: localStorage.getItem('token') } }
        )
        .then((res) => {
          alert(res.data.message);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  return (
    <div style={{ width: '50%' }}>
      <button
        type="button"
        className="btn btn-info"
        onClick={() => setPassword()}
      >
        Change Password
      </button>
      <br />
      <br />
      <ul>
        <li>
          You will receive an email when a document has 30 days left before
          expiration. You will then receive an email each day from 15 days prior
          to expiration. The email notification will stop when you delete the
          document.
        </li>
      </ul>
    </div>
  );
}
