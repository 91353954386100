import React, { useState } from 'react';
import axios from 'axios';

export default function Login(props) {
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  let { username, password } = state;

  function login(e) {
    e.preventDefault();
    axios
      .post(`${props.url}:4000/api/v1/login`, {
        username,
        password,
      })
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem('token', res.data.token);
          window.location.href = '/';
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className="row">
      <div className="col-auto">
        <form onSubmit={(e) => login(e)}>
          <input
            autoFocus
            type="text"
            className="below-input"
            aria-label="Username"
            placeholder="Username"
            onChange={(e) => setState({ ...state, username: e.target.value })}
            value={username}
          />
          <input
            type="password"
            className="below-input"
            aria-label="Password"
            placeholder="Password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            value={password}
          />
          <button type="submit" className="btn btn-success">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
