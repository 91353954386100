import React from 'react';
import { Link } from 'react-router-dom';
import { logout, getUser } from '../Auth';

export default function NavBar() {
  return (
    <div>
      <nav className="navbar navbar-dark bg-primary">
        <Link to="/">
          <span className="navbar-brand">DocMan&nbsp;|&nbsp;{getUser()}</span>
        </Link>
        <img src="logo.png" alt="Logo" height="40" />
        <div className="form-inline">
          <Link to="/">
            <i className="fa fa-home fa-lg icon" aria-hidden="true" />
          </Link>
          &emsp;
          <Link to="/admin">
            <i className="fa fa-user fa-lg icon" aria-hidden="true" />
          </Link>
          &emsp;
          <Link to="/about">
            <i className="fa fa-question fa-lg icon" aria-hidden="true" />
          </Link>
          &emsp;
          <i
            className="fa fa-sign-out fa-lg icon"
            aria-hidden="true"
            onClick={() => logout()}
          />
        </div>
      </nav>
    </div>
  );
}
