import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getUser, isRole } from '../Auth';

export default function FileUpload(props) {
  const [state, setState] = useState({
    file: '',
    date: new Date(),
    selectedDocType: '',
    docTypes: [],
    percent: '',
  });
  const [selectedUsername, setSelectedUsername] = useState('');
  const [users, setUsers] = useState([]);

  let { file, date, selectedDocType, docTypes, percent } = state;

  useEffect(() => {
    isRole('administrator') && getUsers();
    getDocTypes();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUsers() {
    axios
      .get(`${props.url}:4000/api/v1/getusers`, {
        headers: { token: localStorage.getItem('token') },
      })
      .then((res) => {
        if (res.data.success) {
          setUsers(res.data.users);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function getDocTypes() {
    axios
      .get(`${props.url}:4000/api/v1/getdoctypes`, {
        headers: { token: localStorage.getItem('token') },
      })
      .then((res) => {
        if (res.data.success) {
          setState({ ...state, docTypes: res.data.doctypes });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleSelect(e) {
    setState({ ...state, selectedDocType: e.target.value });
  }

  function handleSelectUsername(e) {
    setSelectedUsername(e.target.value);
  }

  function onFileChange(e) {
    setState({ ...state, file: e.target.files[0] });
  }

  function onSubmit(e) {
    e.preventDefault();
    if (selectedDocType === '' || selectedDocType === 'Select a Doc Type') {
      alert('You must select a Doc Type');
    } else if (file.size > 100000000) {
      alert('Please select a smaller file size (max 100 MB)');
    } else {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'username',
        selectedUsername === '' ? getUser() : selectedUsername,
      );
      formData.append('doctype', selectedDocType);
      formData.append('expirationdate', date);

      axios
        .post(`${props.url}:4000/api/v1/upload`, formData, {
          headers: { token: localStorage.getItem('token') },
          onUploadProgress: (progressEvent) =>
            setState({
              ...state,
              percent:
                ((progressEvent.loaded / progressEvent.total) * 100).toFixed() +
                '%',
            }),
        })
        .then((res) => {
          if (res.data.success) {
            // alert(res.data.message);
            props.getUploads();
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  return (
    <div className="container" style={{ padding: '1em' }}>
      <ol>
        <li className="bottom-margin">
          <label>Doc Type&nbsp;</label>
          <select id="selectedDocType" onChange={handleSelect}>
            <option defaultValue>Select a Doc Type</option>
            {docTypes.map((doctype, index) => (
              <option key={index} value={doctype.doctypename}>
                {doctype.doctypename}
              </option>
            ))}
          </select>
        </li>
        <li className="bottom-margin">
          <input type="file" onChange={onFileChange} />
        </li>
        <li className="bottom-margin">
          <label>Expiration Date&nbsp;</label>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={date}
            onChange={(date) => setState({ ...state, date })}
          />
        </li>
        {isRole('administrator') && (
          <li className="bottom-margin">
            <label>Username&nbsp;</label>
            <select id="selectedUsername" onChange={handleSelectUsername}>
              <option defaultValue>Select a Username</option>
              {users.map((user, index) => (
                <option key={index} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
          </li>
        )}
        <li className="bottom-margin">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <span>
                <button type="submit" className="btn btn-success">
                  Upload
                </button>
                &nbsp;{percent}
              </span>
            </div>
          </form>
        </li>
      </ol>
    </div>
  );
}
