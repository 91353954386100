import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

export default function AddUser(props) {
  const [state, setState] = useState({
    show: false,
    username: '',
    email: '',
  });

  let { show, username, email } = state;

  const handleClose = () => {
    setState({ show: false });
  };
  const handleShow = () => {
    setState({ show: true });
  };

  function addUser() {
    axios
      .post(
        `${props.url}:4000/api/v1/adduser`,
        {
          username,
          email,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          handleClose();
          props.getUsers();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleKeyPress(target) {
    if (target.charCode === 13) {
      addUser();
    }
  }

  function focus() {
    document.getElementById('username').focus();
  }

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        Add User
      </button>

      <Modal show={show} onHide={handleClose} onEntered={() => focus()}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Enter new username (format "johndoe")
          <div className="input-group mb-3">
            <input
              id="username"
              type="text"
              className="form-control"
              placeholder="Username"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={(e) => setState({ ...state, username: e.target.value })}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => setState({ ...state, email: e.target.value })}
              onKeyPress={handleKeyPress}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => addUser()}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
