import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileUpload from './FileUpload';
import { isRole } from '../Auth';

export default function Home(props) {
  const [state, setState] = useState({
    uploads: [],
  });

  const [filter, setFilter] = useState('');

  let { uploads } = state;

  useEffect(() => {
    getUploads();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUploads() {
    axios
      .get(`${props.url}:4000/api/v1/getuploads`, {
        headers: { token: localStorage.getItem('token') },
      })
      .then((res) => {
        if (res.data.success) {
          setState({ ...state, uploads: res.data.uploads });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function deleteUpload(id) {
    axios
      .post(
        `${props.url}:4000/api/v1/deleteupload`,
        {
          id,
        },
        { headers: { token: localStorage.getItem('token') } },
      )
      .then((res) => {
        if (res.data.success) {
          getUploads();
        } else {
          alert(res.data.message);
        }
        //alert(res.data.message);
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className="row">
      <div className="col-auto" style={{ margin: '0 auto' }}>
        <a
          href={`${
            props.url
          }:4000/api/v1/publicuploads/crna-associates-tb-form.pdf?token=${localStorage.getItem(
            'token',
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            CRNA Associates TB Form
          </p>
        </a>

        <a
          href={`${
            props.url
          }:4000/api/v1/publicuploads/independent-contractor-agreement.pdf?token=${localStorage.getItem(
            'token',
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Independent Contractor Agreement
          </p>
        </a>

        <a
          href={`${
            props.url
          }:4000/api/v1/publicuploads/shire-application.pdf?token=${localStorage.getItem(
            'token',
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ color: 'red', fontWeight: 'bold' }}>Shire Application</p>
        </a>

        <a
          href={`${
            props.url
          }:4000/api/v1/publicuploads/special-limited-power-of-attorney.pdf?token=${localStorage.getItem(
            'token',
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Special Limited Power of Attorney
          </p>
        </a>

        {!isRole('view-only') && (
          <FileUpload url={props.url} getUploads={getUploads} />
        )}

        <table className="table table-sm table-bordered table-striped table-centered table-responsive">
          <thead>
            <tr>
              <th>
                <input
                  type="text"
                  aria-label="Filter"
                  placeholder="Filter"
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                />
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>File Name (click to download)</th>
              <th>Upload Date</th>
              <th>Expiration Date</th>
              <th>Size</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {uploads
              .filter(function (row) {
                if (filter) {
                  return row.filename
                    .toLowerCase()
                    .includes(filter.toLowerCase());
                }
                return row;
              })
              .map((upload, index) => (
                <tr
                  key={index}
                  className={upload.enabled === 1 ? '' : 'disabled-row'}
                >
                  <td>
                    <a
                      href={`${props.url}:4000/api/v1/privateuploads/${
                        upload.id
                      }?token=${localStorage.getItem('token')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {upload.filename}
                    </a>
                  </td>
                  <td>{upload.uploaddate}</td>
                  <td>{upload.expirationdate}</td>
                  <td>{upload.filesize}</td>
                  <td>
                    <button
                      type="button"
                      className="fa-button"
                      onClick={() => deleteUpload(upload.id)}
                    >
                      <i className="fa fa-trash" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
